import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  footer: {
    display: "block",
    margin: theme.spacing(2),
  },
  avatar: {
    display: "flex",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  footerGrid: {
    textAlign: "center",
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid container spacing={2} justify="center">
        <Grid item xs={6} sm={4} className={classes.footerGrid}><Typography>© {new Date().getFullYear()} Chris Weber</Typography></Grid>
      </Grid>
    </footer>
  )
}

export default Footer;