import React from "react"
import { Breadcrumbs, Link, Typography } from "@material-ui/core"


const PostNavigation = ({siteTitle, title}) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/">
        {siteTitle}
      </Link>
      <Typography color="textPrimary"></Typography>
    </Breadcrumbs>
  )
}

export default PostNavigation
