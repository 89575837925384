import React from "react";
import { Typography, Container, Divider } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: "center",
    },
    date: {
        textAlign: "end",
    },
    header: {
        marginBottom: theme.spacing(3),
    },
}))

const PostHeader = ({title, date}) => {

    const classes = useStyles();

    const displayDate = new Date(date).toDateString('%b %d, %Y');  

    return (
        <Container className={classes.header}>
            <Typography variant="h4" className={classes.title}>{title}</Typography>
            <Divider />
            <Typography variant="subtitle2" className={classes.date}>{displayDate}</Typography>
        </Container>  
    );
}

export default PostHeader;