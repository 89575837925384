/**
 * PostLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Layout from "../layout"
import { Container } from "@material-ui/core"

import PostHeader from "./postHeader";
import SEO from "../seo";
import PostNavigation from "./navigation";
import PostFooter from "./postFooter";

const PostLayout = ({ children, pageContext }) => {

  const { title, author, date, tldr, image } = pageContext.frontmatter

  return (
    <Layout>
      <SEO title={title} description={tldr} image={`/posts/${image}`} />
      <PostNavigation siteTitle="Home" title={title} />
      <PostHeader title={title} author={author} date={date}/>
      <Container maxWidth="md">
        {children}
      </Container>
      <PostFooter />
    </Layout>
  )
}

PostLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PostLayout
