import React from "react";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    disclaimer: {
        fontStyle: "italic",
        fontSize: 12,
        textAlign: "center",
    },
  }));

const PostFooter = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="md">
        <Typography className={classes.disclaimer}>
            All thoughts and ideas expressed on this site, unless explicitly state otherwise, are solely the representation of myself Chris Weber, and not of my employer, company, family, or friends.
        </Typography>
        </Container>
    )
}

export default PostFooter;